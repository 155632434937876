import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { DocumentDuplicateIcon, MapIcon, UserIcon } from "@heroicons/react/24/solid";
import ServersPreload from "./ServersPreload"; // Імпорт компоненту ServersPreload
import { useSnackbar } from "../../context/SnackbarContext";

const serversData = [
  {
    id: 1,
    image: "/server-bg.jpg",
    playersOnline: 69,
    title: "Vanilla",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis obcaecati unde quod error? Dolorem quibusdam nihil corrupti doloremque recusandae facere.",
    isOnline: true,
    ip: "mc.magatamy.com",
    map: "https://map-vanilla.magatamy.com/",
    version: "1.21",
  },
  {
    id: 2,
    image: "/server-bg2.jpg",
    playersOnline: 0,
    title: "Vanilla YT",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis obcaecati unde quod error? Dolorem quibusdam nihil corrupti doloremque recusandae facere.",
    isOnline: false,
    ip: "mc.magatamy.com",
    version: "1.21",
  },
  {
    id: 3,
    image: "/server-bg3.jpg",
    playersOnline: 112,
    title: "Box",
    shortDescription:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis obcaecati unde quod error? Dolorem quibusdam nihil corrupti doloremque recusandae facere.",
    isOnline: true,
    ip: "mc.magatamy.com",
    version: "1.21",
  },
];
// const serversData = [];

function ServerInfoBlock({ server }) {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();

  const copyToClipboard = (text) => {
    if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
      // Використовуємо Clipboard API, якщо він доступний
      navigator.clipboard
        .writeText(text)
        .then(() => showSnackbar("text_copied", "success"))
        .catch(() => showSnackbar("failed_to_copy", "error"));
    } else {
      // Альтернативний метод для старих браузерів або мобільних пристроїв
      try {
        const textarea = document.createElement("textarea");
        textarea.value = text;
        textarea.style.position = "fixed"; // Щоб не викликати скролінг
        textarea.style.opacity = "0"; // Робимо невидимим
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        const successful = document.execCommand("copy");
        document.body.removeChild(textarea);
  
        if (successful) {
          showSnackbar("text_copied", "success");
        } else {
          throw new Error("Copy failed");
        }
      } catch (error) {
        showSnackbar("failed_to_copy", "error");
      }
    }
  };
  

  return (
    <div
      className="col-span-1 lg:col-span-2 xl:col-span-1 min-h-64 bg-cover rounded-md drop-shadow-lg"
      style={{ backgroundImage: `url(${server.image})` }}
    >
      <div className="h-full w-full p-4 bg-gradient-to-t from-orange-900 to-orange-900/50 rounded-md flex flex-col justify-between">
        <div className="flex flex-col sm:flex-row justify-between items-center gap-y-2">
          <p className="my-1 text-xs sm:text-sm self-start order-2 sm:order-1">
            {t("players_online")}: <UserIcon className="w-5 h-5 me-1 inline -mt-1" />{server.playersOnline}
          </p>
          {server.isOnline ? (
            <p className="border border-neutral-300 px-5 py-1 rounded-full bg-black/10 self-end order-1 sm:order-2">
              {t("online")}{" "}
              <span className="relative h-4 w-4 ms-2">
                <span className="animate-ping absolute inline-flex h-3 w-3 sm:h-4 sm:w-4 inset-0 top-0.5 rounded-full bg-green-500 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-4 w-4 bg-green-500"></span>
              </span>
            </p>
          ) : (
            <p className="border border-1 px-5 py-1 rounded-full bg-black/10 self-end order-1 sm:order-2">
              {t("offline")}{" "}
              <span className="relative inline-flex rounded-full h-4 w-4 bg-red-500 ms-2"></span>
            </p>
          )}
        </div>
        <div className="text-start">
          <p className="text-xl my-3">{server.title}</p>
          <p className="text-sm">{server.shortDescription}</p>
          <div className="flex flex-col md:flex-row gap-2 mt-4">
            <div className="bg-pink-950 hover:bg-pink-800 py-3 text-center rounded-md flex-1 cursor-pointer transition-all duration-300 ease-in-out" onClick={() => copyToClipboard(server.ip)}>
              <p className="">{server.ip} <DocumentDuplicateIcon className="h-5 w-5 inline ms-2 align-text-top"/></p>
            </div>
            <div className="flex gap-2 justify-end">
              {server.map ? <a target="_blank" href={server.map} className="bg-pink-950 hover:bg-pink-800 py-3 text-center rounded-md px-3 transition-all duration-300 ease-in-out"><MapIcon className="h-6 w-6" /></a> : ""}
              <div className="bg-pink-950 py-3 text-center rounded-md px-6"><p>{t("version")}: {server.version}</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Servers() {
  return (
    <Suspense fallback={<ServersPreload />}>
      {serversData.length === 0 ? (
        <ServersPreload />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 py-4 gap-8 lg:gap-x-4 xl:gap-8">
          {serversData.map((server, index) => (
            <React.Fragment key={server.id}>
              {index % 2 === 0 ? (
                <>
                  <ServerInfoBlock server={server} />
                  <div className="text-start py-4 items-center bg-gradient-to-l from-orange-500/40 rounded-md hidden lg:flex">
                    <p className="w-full sm:w-2/3 lg:w-full xl:w-2/3 leading-8 pe-4 md:pe-0">{server.description}</p>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-end py-4 flex items-center bg-gradient-to-r from-pink-500/40 rounded-md hidden lg:flex">
                    <p className="ms-auto w-full sm:w-2/3 lg:w-full xl:w-2/3 leading-8 ps-4 md:ps-0">{server.description}</p>
                  </div>
                  <ServerInfoBlock server={server} />
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </Suspense>
  );
}


export default Servers;
