function ServerPreload() {
    return (
      <div className="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-2 grid-rows-3 py-4 gap-8 lg:gap-x-4 xl:gap-8">
        {Array.from({ length: 2 }).map((_, index) => (
          <div
            key={index}
            className={"bg-orange-900 animate-pulse rounded-md h-64 col-span-1 drop-shadow-lg " + (index % 2 == 0 ? "col-start-2 col-end-2 row-start-2" : "")}
          >
            <div className="h-full bg-gradient-to-b from-transparent to-pink-950 flex flex-col justify-between p-4 rounded-md">
              <div className="flex justify-between">
                <p className="text-lg mb-3 bg-gray-300 h-5 w-2/3 rounded"></p>
                <p className="bg-gray-300 h-5 w-20 rounded"></p>
              </div>
              <div className="text-start">
                <p className="text-sm bg-gray-300 h-3 w-5/6 rounded"></p>
                <p className="text-sm bg-gray-300 h-3 w-5/6 rounded mt-1"></p>
                <div className="flex flex-col md:flex-row gap-2 mt-4">
                  <div className="bg-pink-950 animate-pulse py-3 text-center rounded-md flex-1">
                    <p className="bg-gray-300 h-5 w-full rounded"></p>
                  </div>
                  <div className="flex gap-2 justify-end">
                    <div className="bg-pink-950 animate-pulse py-3 text-center rounded-md px-3">
                      <p className="bg-gray-300 h-5 w-6 rounded"></p>
                    </div>
                    <div className="bg-pink-950 animate-pulse py-3 text-center rounded-md px-6">
                      <p className="bg-gray-300 h-5 w-20 rounded"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
  
  export default ServerPreload;
  