import React, { useState, useEffect } from "react";
import { FaDiscord } from "react-icons/fa6";
import { MdOutlineShoppingCart, MdShoppingCart } from 'react-icons/md';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCart } from "../../context/CartContext";
import { useSnackbar } from "../../context/SnackbarContext";

const sections = [
  {
    title: "Build Your Own Unique Discord Bot",
    description:
      "The best Discord bot is the one you can customize yourself. Let’s make the Discord client better than ever. Customize MEE6 to reflect your server's personality. Unlock its potential with a powerful plugin system. Utilize MEE6's Bot Personalizer feature to make your server unique so the bot integrates into your server's universe and aligns with your branding. You can create a unique space for your community. Take control of MEE6 and tailor it to match your server's identity and enhance the user experience. It’s easy! With the help of our simple tutorial and helpful support team, you’ll be seeing all of the cool possibilities that MEE6 can bring to your Discord server in no time. Are you ready to see what MEE6 bot can do?",
    image: "bot1.webp",
  },
  {
    title: "Engage Your Community",
    description:
      "Engagement is key to building a successful community. MEE6 provides tools to keep your community members engaged and active with features like leveling, custom commands, moderation, and much more. Make your Discord server a place where people want to be!",
    image: "bot2.webp",
  },
  {
    title: "Enhance Server Interaction",
    description:
      "Make your server interactive and fun with MEE6. From rewarding your members to managing moderation, MEE6 has tools to make your server more engaging and enjoyable!",
    image: "bot3.webp",
  },
  {
    title: "Create a Unique Experience",
    description:
      "MEE6’s customization options allow you to create a Discord server that stands out, reflects your community’s culture, and provides a memorable experience for your members.",
    image: "bot4.webp",
  },
];

const Buttons = ({ productId, price, t }) => {
  const { addToCart, removeFromCart, isInCart } = useCart();
  const [isFilled, setIsFilled] = useState(isInCart(productId));
  const { showSnackbar } = useSnackbar();
  
  // Оновлення стану кнопки при зміні корзини
  useEffect(() => {
    setIsFilled(isInCart(productId));
  }, [isInCart, productId]);

  const handleToggleIcon = () => {
    if (isFilled) {
      removeFromCart(productId);
      showSnackbar("removed_from_cart", "warning");
    } else {
      addToCart(productId);
      showSnackbar("added_to_cart", "success");
    }
    setIsFilled(!isFilled);
  };

  return (
    <div className="flex justify-between sm:justify-start flex-col sm:flex-row lg:flex-col xl:flex-row gap-2 xl:gap-6">
      <button
        onClick={handleToggleIcon}
        className="px-6 py-2 text-center rounded-md bg-pink-600 hover:bg-pink-700 transition-all duration-300 ease-in-out"
      >
        {isFilled ? (
          <MdShoppingCart className="inline w-5 h-5 me-3 -mt-1" />
        ) : (
          <MdOutlineShoppingCart className="inline w-5 h-5 me-3 -mt-1" />
        )}
        €{price}
      </button>

      <Link
        className="flex-1 text-center px-6 py-2 rounded-md bg-white/10 hover:bg-white/20 transition-all duration-300 ease-in-out"
        to="/add-to-discord"
      >
        <FaDiscord className="inline w-5 h-5 me-3 -mt-1" /> {t("add_to_discord")}
      </Link>
    </div>
  );
};

function Bot() {
  const { t } = useTranslation();
  return (
    <div className="text-start">
      <div
        className="bg-cover h-dvh md:bg-center -mx-4 px-4 py-32 md:px-20 -mt-16 flex items-center"
        style={{ backgroundImage: `url(/bot-background2.png)` }}
      >
        <div className="w-full lg:w-2/3 xl:w-1/2">
          <p className="text-3xl md:text-5xl font-bold mb-8 leading-normal">
            The best <br /> all-in-one bot <br /> for Discord
          </p>
          <p className="text-sm text-neutral-300 mb-8 leading-normal">
            MEE6 is a complete Discord bot, easy-to-use, that millions of
            Discord servers worldwide trust to manage, entertain, and grow their
            community.
          </p>
          <Buttons productId={1000} price={4.99} t={t} />  {/* Додаємо кнопку для товару */}
        </div>
      </div>
      <div className="py-4 px-0 xl:p-10">
        {sections.map((section, index) => (
          <div
            key={index}
            className="lg:grid grid-cols-7 gap-x-4 xl:gap-x-24 mb-20"
          >
            <div
              className={`col-span-4 flex items-center ${
                index % 2 === 0 ? "lg:order-last" : ""
              }`}
            >
              <img src={section.image} className="w-full" alt="" />
            </div>
            <div className="col-span-3 flex flex-col justify-between">
              <p className="text-3xl font-bold leading-normal">
                {section.title}
              </p>
              <p className="text-xs leading-relaxed text-neutral-200 tracking-wide my-6">
                {section.description}
              </p>
              <Buttons productId={1000} price={4.99} t={t} />
            </div>
          </div>
        ))}
      </div>
      <div className="py-20 px-4 text-center bg-orange-950 -mx-4">
        <p className="text-3xl mb-10">Build the best Discord server</p>
        <Link
          to="#"
          className="text-lg px-12 py-4 rounded-md bg-orange-500 hover:bg-orange-600 transition-all duration-300 ease-in-out"
        >
          {t("add_to_discord")}
        </Link>
      </div>
    </div>
  );
}

export default Bot;
