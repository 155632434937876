import React from "react";
import { FaDiscord, FaGoogle } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Auth() {
  const { t } = useTranslation();

  return (
    <div className="h-full flex items-center justify-center text-white mb-10">
      <div className="">
        <h2 className="text-2xl font-bold text-center mb-4">{t("auth_login")}</h2>
        <form className="space-y-4 text-left">
          {/* Поле для ніку або пошти */}
          <div>
            <label className="block text-neutral-400 mb-1">{t("auth_username_label")}</label>
            <input
              type="text"
              placeholder={t("auth_username_placeholder")}
              className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            />
          </div>
          {/* Поле для паролю */}
          <div>
            <label className="block text-neutral-400 mb-1">{t("auth_password_label")}</label>
            <input
              type="password"
              placeholder={t("auth_password_placeholder")}
              className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
            />
          </div>
          {/* Кнопка для входу */}
          <button className="w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
            {t("auth_login_button")}
          </button>
        </form>

        {/* Роздільна лінія */}
        <div className="text-center my-4 text-neutral-500">{t("auth_or")}</div>

        {/* Кнопки входу через сторонні сервіси */}
        <button className="w-full flex items-center justify-center bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 mb-2">
          <FaDiscord alt="Discord" className="h-6 w-6 mr-3" />
          {t("auth_login_discord")}
        </button>
        <button className="w-full flex items-center justify-center bg-red-600 text-white py-2 rounded-md hover:bg-red-700">
          <FaGoogle alt="Google" className="h-6 w-6 mr-3" />
          {t("auth_login_google")}
        </button>

        {/* Посилання для переходу до реєстрації */}
        <p className="text-neutral-500 mt-4 text-center">
          {t("auth_no_account")}{" "}
          <Link to="/registration" className="text-orange-500 hover:underline">
            {t("auth_register_here")}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Auth;
