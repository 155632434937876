import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";

const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: "",
    type: "info", // Типи: "success", "error", "warning", "info"
    isOpen: false,
  });

  const showSnackbar = useCallback((message, type = "info") => {
    setSnackbar({ message, type, isOpen: true });
    setTimeout(() => {
      setSnackbar((prev) => ({ ...prev, isOpen: false }));
    }, 3000); // Зникнення через 3 секунди
  }, []);

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      {snackbar.isOpen && (
        <Snackbar message={snackbar.message} type={snackbar.type} />
      )}
    </SnackbarContext.Provider>
  );
};

const Snackbar = ({ message, type }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true); // Керує видимістю

  useEffect(() => {
    const hideTimeout = setTimeout(() => {
      setIsVisible(false); // Приховуємо через 3 секунди
    }, 2700); // 2700мс, щоб залишити трохи часу для анімації зникання

    return () => clearTimeout(hideTimeout);
  }, []);

  const typeStyles = {
    success: "bg-orange-700",
    error: "bg-red-700",
    warning: "bg-pink-700",
    info: "bg-neutral-700",
  };

  return (
    <div
      className={`fixed bottom-4 right-4 transform px-6 py-3 rounded-md text-white shadow-lg transition-all duration-300 ease-in-out ${
        isVisible ? "opacity-100 scale-100" : "opacity-0 scale-90"
      } ${typeStyles[type]}`}
    >
      {t(message)}
    </div>
  );
};
