import { XCircleIcon, PlusIcon, MinusIcon } from "@heroicons/react/24/solid";
import { MdShoppingCart } from "react-icons/md";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const initialGoodsData = [
  {
    id: 1,
    title: "Minecraft",
    price: 4.99,
    quantity: 1,
    type: "minecraft",
    image: "/news1.png",
  },
  {
    id: 2,
    title: "Bot Subscription",
    price: 2.99,
    quantity: 1,
    type: "subscription",
    image: "/news2.png",
  },
];

function CheckoutForm({ itemType, t }) {
  return (
    <div className="mt-4">
      <input
        type="email"
        placeholder={t("email_placeholder")}
        className="w-full p-2 mt-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
      />
      <input
        type="text"
        placeholder={t("promo_placeholder")}
        className="w-full p-2 mt-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
      />
      {itemType === "minecraft" && (
        <input
          type="text"
          placeholder={t("minecraft_nickname_placeholder")}
          className="w-full p-2 mt-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
        />
      )}
    </div>
  );
}

function renderGoods(goodsData, increaseQuantity, decreaseQuantity, removeItem, t) {
  return goodsData.map((goodItem) => (
    <div
      key={goodItem.id}
      className="bg-neutral-900 rounded-md bg-cover drop-shadow-md p-2 flex gap-x-2 md:gap-x-4 items-center"
    >
      <img
        src={goodItem.image}
        className="h-16 w-16 sm:h-20 sm:w-20 md:h-24 md:w-24 rounded-md"
        alt={t("goods_item_alt", { title: goodItem.title })}
      />
      <div className="flex-1 text-start flex flex-col gap-y-2">
        <p className="mb-1 text-sm sm:text-base font-semibold text-white">{t(goodItem.title)}</p>
        <p className="text-sm text-gray-400">${goodItem.price.toFixed(2)}</p>
      </div>
      <div className="flex items-center gap-x-0 md:gap-x-2">
        <button onClick={() => decreaseQuantity(goodItem.id)} className="text-gray-300 hover:text-white">
          <MinusIcon className="h-5 w-5" />
        </button>
        <span className="px-1 sm:px-2 text-white">{goodItem.quantity}</span>
        <button onClick={() => increaseQuantity(goodItem.id)} className="text-gray-300 hover:text-white">
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
      <button onClick={() => removeItem(goodItem.id)} className="self-start text-pink-600 hover:text-pink-700 text-white">
        <XCircleIcon className="h-5 w-5 md:h-7 md:w-7" />
      </button>
    </div>
  ));
}

function Cart() {
  const { t } = useTranslation();
  const [goodsData, setGoodsData] = useState(initialGoodsData);

  const increaseQuantity = (id) => {
    setGoodsData((prevGoods) =>
      prevGoods.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const decreaseQuantity = (id) => {
    setGoodsData((prevGoods) =>
      prevGoods.map((item) =>
        item.id === id && item.quantity > 1
          ? { ...item, quantity: item.quantity - 1 } : item
      )
    );
  };

  const removeItem = (id) => {
    setGoodsData((prevGoods) => prevGoods.filter((item) => item.id !== id));
  };

  const totalAmount = goodsData.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const totalItems = goodsData.reduce((sum, item) => sum + item.quantity, 0);

  return (
    <div className="md:px-6">
      <p className="text-2xl text-white text-start">
        <MdShoppingCart className="h-6 w-6 inline -mt-1" /> {t("cart")}
      </p>
      <div className="grid md:grid-cols-6 gap-4 mt-4">
        <div className="md:col-span-4 flex flex-col gap-4 py-4">
          {goodsData.length > 0 ? (
            renderGoods(goodsData, increaseQuantity, decreaseQuantity, removeItem, t)
          ) : (
            <p className="text-gray-500 text-center">{t("cart_empty")}</p>
          )}
        </div>
        <div className="md:col-span-2 bg-neutral-800 pb-4 md:p-4 rounded-md">
          <p className="text-xl font-semibold text-white">{t("summary")}</p>
          <div className="flex justify-between mt-4">
            <p className="text-gray-400">{t("items")}: </p>
            <p className="text-white">{totalItems}</p>
          </div>
          <div className="flex justify-between mt-2">
            <p className="text-gray-400">{t("total")}: </p>
            <p className="text-white">${totalAmount.toFixed(2)}</p>
          </div>
          {goodsData.map((item) => (
            <CheckoutForm key={item.id} itemType={item.type} t={t} />
          ))}
          <button className="mt-6 w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
            {t("checkout")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;
