import React from "react";
import { MdOutlineShoppingCart, MdShoppingCart } from "react-icons/md";
import { useCart } from "../../context/CartContext"; // імпортуємо з контексту
import { useSnackbar } from "../../context/SnackbarContext";

const ProductCard = ({ product }) => {
  const { addToCart, removeFromCart, isInCart } = useCart();
  const { id, name, price, discount, image, originalPrice } = product;
  const { showSnackbar } = useSnackbar();

  const handleCartClick = () => {
    if (isInCart(id)) {
      removeFromCart(id);
      showSnackbar("removed_from_cart", "warning");
    } else {
      addToCart(id);
      showSnackbar("added_to_cart", "success");
    }
  };

  return (
    <div className="bg-gradient-to-b from-neutral-900 from-40% to-orange-950 p-4 rounded-lg drop-shadow-md relative text-white">
      {/* Знижка */}
      {discount && (
        <div className="absolute top-4 right-4 bg-orange-600 text-xs font-bold px-3 py-1 rounded-full z-10">
          {discount}%
        </div>
      )}

      {/* Зображення */}
      <div className="flex justify-center mb-4">
        <img
          src={image}
          alt={name}
          className="w-full h-auto lg:w-32 lg:h-32 object-cover"
        />
      </div>

      <h3 className="text-left font-bold md:text-lg mb-2">{name}</h3>

      {/* Ціна */}
      <div className="flex justify-between items-center">
        <div className="text-start flex flex-col">
          {originalPrice && (
            <span className="text-xs line-through text-neutral-200 font-extralight mr-2">
              ${originalPrice.toFixed(2)}
            </span>
          )}
          <span className="text-orange-500 font-bold">${price.toFixed(2)}</span>
        </div>
        <button
          onClick={handleCartClick}
          className="self-end bg-orange-500 hover:bg-orange-600 transition-colors duration-200 text-white font-bold p-2 rounded-lg"
        >
          {isInCart(id) ? (
            <MdShoppingCart className="w-6 h-6 fill-current" />
          ) : (
            <MdOutlineShoppingCart className="w-6 h-6" />
          )}
        </button>
      </div>
    </div>
  );
};

export default ProductCard;
