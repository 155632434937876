import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaDiscord, FaGoogle } from "react-icons/fa6";

function Registration() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [timer, setTimer] = useState(0);
  const [step, setStep] = useState(1); // 1 - основна форма, 2 - підтвердження коду

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const isNicknameValid = nickname.length >= 3;
  const isEmailValid = emailRegex.test(email);
  const isPasswordValid = password.length >= 6;
  const isPasswordMatch = password === confirmPassword;
  const isFormValid = isEmailValid && isPasswordValid && isPasswordMatch;

  const handleSendCode = () => {
    setIsCodeSent(true);
    setTimer(60);

    // Емуляція відправлення коду підтвердження
    console.log(`Confirmation code sent to ${email}`);

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(countdown);
          setIsCodeSent(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleNextStep = () => {
    if (isFormValid) {
      setStep(2);
      handleSendCode();
    }
  };

  const handleConfirmCode = () => {
    if (code === "123456") {
      navigate("/profile");
      alert(t("registration_success"));
      // Логіка завершення реєстрації
    } else {
      alert(t("invalid_code"));
    }
  };

  return (
    <div className="h-full flex items-center justify-center text-white mb-10">
      <div className="w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-4">{t("register")}</h2>

        {step === 1 && (
          <div className="">
            <form className="space-y-4 text-start">
              {/* Поле для ніку */}
            <div>
                <label className="block text-neutral-400 mb-1">
                  {t("nickname")}
                </label>
                <input
                  type="nickname"
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  placeholder={t("nickname_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isNicknameValid || !nickname
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
                {!isEmailValid && email && (
                  <p className="text-red-500 text-sm">{t("email_invalid")}</p>
                )}
              </div>
              {/* Поле для пошти */}
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("email")}
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={t("email_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isEmailValid || !email
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
                {!isEmailValid && email && (
                  <p className="text-red-500 text-sm">{t("email_invalid")}</p>
                )}
              </div>
              {/* Поле для паролю */}
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("password")}
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={t("password_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isPasswordValid || !password
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
                {!isPasswordValid && password && (
                  <p className="text-red-500 text-sm">
                    {t("password_invalid")}
                  </p>
                )}
              </div>
              {/* Поле для підтвердження паролю */}
              <div>
                <label className="block text-neutral-400 mb-1">
                  {t("confirm_password")}
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder={t("confirm_password_placeholder")}
                  className={`w-full p-2 rounded bg-neutral-700 border ${
                    isPasswordMatch || !confirmPassword
                      ? "border-neutral-600"
                      : "border-red-500"
                  } focus:outline-none focus:border-orange-500`}
                />
                {!isPasswordMatch && confirmPassword && (
                  <p className="text-red-500 text-sm">
                    {t("password_mismatch")}
                  </p>
                )}
              </div>
              {/* Кнопка для переходу до наступного кроку */}
              <button
                type="button"
                onClick={handleNextStep}
                disabled={!isFormValid}
                className={`w-full py-2 rounded-md ${
                  isFormValid
                    ? "bg-orange-500 hover:bg-orange-600 text-white"
                    : "bg-neutral-500 text-neutral-300 cursor-not-allowed"
                }`}
              >
                {t("next_step")}
              </button>
            </form>
            {/* Роздільна лінія */}
            <div className="text-center my-4 text-neutral-500">
              {t("auth_or")}
            </div>

            {/* Кнопки входу через сторонні сервіси */}
            <button className="w-full flex items-center justify-center bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 mb-2">
              <FaDiscord alt="Discord" className="h-6 w-6 mr-3" />
              {t("auth_registration_discord")}
            </button>
            <button className="w-full flex items-center justify-center bg-red-600 text-white py-2 rounded-md hover:bg-red-700">
              <FaGoogle alt="Google" className="h-6 w-6 mr-3" />
              {t("auth_registration_google")}
            </button>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-4 text-start">
            <p className="text-neutral-400">{t("code_sent_to", { email })}</p>
            {/* Поле для коду підтвердження */}
            <div>
              <label className="block text-neutral-400 mb-1">
                {t("confirmation_code")}
              </label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={t("confirmation_code_placeholder")}
                className="w-full p-2 rounded bg-neutral-700 border border-neutral-600 focus:outline-none focus:border-orange-500"
              />
              <button
                type="button"
                onClick={handleSendCode}
                disabled={isCodeSent}
                className={`mt-2 px-4 py-2 text-sm rounded ${
                  isCodeSent
                    ? "bg-neutral-500 text-neutral-300 cursor-not-allowed"
                    : "bg-orange-500 text-white hover:bg-orange-600"
                }`}
              >
                {isCodeSent
                  ? t("resend_code_in", { seconds: timer })
                  : t("resend_code")}
              </button>
            </div>
            {/* Кнопка для підтвердження */}
            <button
              type="button"
              onClick={handleConfirmCode}
              className="w-full py-2 rounded-md bg-orange-500 hover:bg-orange-600 text-white"
            >
              {t("confirm")}
            </button>
          </div>
        )}

        {/* Посилання для входу */}
        <p className="text-neutral-500 mt-4 text-center">
          {t("already_have_account")}{" "}
          <Link to="/auth" className="text-orange-500 hover:underline">
            {t("login_here")}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Registration;
