import { ChartBarIcon, BoltIcon, ArrowRightIcon } from "@heroicons/react/24/solid";
import React from "react";
import { FaTelegram, FaDiscord, FaInstagram } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Founders() {
  const { t } = useTranslation();

  const foundersArray = [
    {
      img: "MrBloodyCat.png",
      name: "MrBloodyCat",
      position: "Admin",
      discription: `Bloodycat это не просто человек, это - дух Magatamy. Он полон
            энтузиазма, всегда готов помочь новичкам и поделиться своими
            секретами с опытными игроками.`,
      links: {
        telegram: "@telegram",
        discord: "@telegram",
        instagram: "asda",
      },
    },
    {
      img: "crips.png",
      name: "Nickname",
      position: "Admin",
      discription: `Crips это человек-загадка. Он немногословен, но его действия говорят громче слов.`,
      links: {
        discord: "@telegram",
      },
    },
    {
      img: "https://minotar.net/armor/bust/mhf_steve/500.png",
      name: "Nickname",
      position: "Admin",
      links: {
        telegram: "@telegram",
        instagram: "asda",
      },
    },
    {
      img: "https://minotar.net/armor/bust/mhf_steve/500.png",
      name: "Nickname",
      position: "Admin",
      links: {
        telegram: "@telegram",
      },
    },
  ];

  function renderFounders(foundersArray) {
    return foundersArray.map((founderItem) => (
      <div
        className="p-3 bg-neutral-900 rounded-md drop-shadow-md gap-x-5 grid grid-cols-1 md:grid-cols-2"
        key={founderItem.id}
      >
        <div className="">
          <img src={founderItem.img} className="w-2/3 md:w-full h-auto mx-auto" alt="" />
        </div>
        <div className="flex flex-col justify-between mt-3 md:mt-0">
          <div className="flex justify-between">
            <p className="text-lg font-bold">{founderItem.name}</p>
            <p className="text-lg font-bold text-gray-300">{founderItem.position}</p>
          </div>
          <p className="text-sm my-5">
            {founderItem.discription}
          </p>
          <div className="self-end flex justify-self-end gap-x-4">
            {founderItem.links.telegram && (
              <Link href={founderItem.links.telegram}>
                <FaTelegram className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
              </Link>
            )}
            {founderItem.links.discord && (
              <Link href={founderItem.discord}>
                <FaDiscord className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
              </Link>
            )}
            {founderItem.links.instagram && (
              <Link href={founderItem.instagram}>
                <FaInstagram className="h-7 w-7 transition-all duration-300 ease-in-out hover:text-orange-600" />
              </Link>
            )}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className="py-4">
      <div className="flex justify-between items-center mb-6">
      <p className="text-2xl text-white">
        <BoltIcon className="h-7 w-7 inline -mt-1" />{" "}
        {t("founders")}
      </p>
        <Link
          to="about-us"
          className="self-end border-b border-transparent hover:border-white text-lg hidden sm:block"
        >
          {t('nav_about')}
          <ArrowRightIcon className="ms-4 h-4 w-4 -mt-1 inline" />
        </Link>
      </div>
      
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        {foundersArray.length > 0 ? renderFounders(foundersArray) : <></>}
      </div>
    </div>
  );
}

export default Founders;
