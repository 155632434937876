import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Додано для напівпрозорого заповнення
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler // Реєстрація для заповнення графіку
);

const data = {
  labels: ['1', '2', '3', '4', '5', '6', '7'], // Дні
  datasets: [
    {
      label: 'Server',
      borderColor: '#f97316',
      backgroundColor: 'rgb(249,115,22, 0.25)', // Напівпрозорий фон
      data: [35, 59, 60, 81, 56, 65, 90],
      fill: true, // Активує напівпрозорий фон
      tension: 0.3, // Згладжування лінії, чим більше значення, тим сильніше згладжування (0.4 - помірне)
    },
    {
        label: 'Server 2',
        borderColor: '#db2777',
        backgroundColor: 'rgb(219,39,119, 0.25)', // Напівпрозорий фон
        data: [40, 37, 13, 55, 86, 85, 70],
        fill: true, // Активує напівпрозорий фон
        tension: 0.3, // Згладжування лінії, чим більше значення, тим сильніше згладжування (0.4 - помірне)
      },
      {
        label: 'Server 3',
        borderColor: '#ffffff',
        backgroundColor: 'rgb(255,255,255, 0.25)', // Напівпрозорий фон
        data: [40, 27, 13, 12, 34, 69, 44],
        fill: true, // Активує напівпрозорий фон
        tension: 0.3, // Згладжування лінії, чим більше значення, тим сильніше згладжування (0.4 - помірне)
      },
  ],
};

const options = {
  scales: {
    x: {
      ticks: {
        color: '#ffffff', // Білий текст на осі x
      },
    },
    y: {
      ticks: {
        color: '#ffffff', // Білий текст на осі y
      },
    },
  },
  plugins: {
    legend: {
      labels: {
        color: '#ffffff', // Білий текст у легенді
      },
    },
  },
};

function ServerChart() {
  return <Line data={data} options={options} />;
}

export default ServerChart;
