import { FireIcon, NewspaperIcon } from "@heroicons/react/24/solid";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import NewsPreload from "./NewsPreload";

// Масив з новинами
const newsData = [
  {
    id: 1,
    new: true,
    title: "Lorem, ipsum dolor.",
    description:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Repellendus odit, nihil vel at repudiandae dolore voluptatibus fuga necessitatibus. Doloribus eius, cupiditate illo quod perspiciatis laboriosam sit vero dicta beatae nostrum.",
    image: "/news1.png",
  },
  {
    id: 2,
    new: false,
    title: "Lorem.",
    description:
      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nemo laudantium possimus odit ex impedit recusandae ut optio distinctio mollitia sunt! Voluptate impedit eum a facere modi optio aliquam quisquam? Nulla.",
    image: "/news2.png",
  },
];

// Масив з новинами (тимчасово пустий для демонстрації)
//const newsData = []; // Якщо є новини, заповнюється масивом новин


// Функція для рендерингу новин
function renderNews(newsArray) {
  return newsArray.map((newsItem) => (
    <div
      key={newsItem.id}
      className="bg-orange-950 rounded-md min-h-64 bg-cover drop-shadow-lg"
      style={{ backgroundImage: `url('${newsItem.image}')` }}
    >
      <div className="h-full bg-gradient-to-b from-transparent to-pink-950 flex flex-col justify-between items-end p-2 rounded-md">
      {newsItem.new ? <FireIcon className="w-8 h-8 p-1 bg-orange-600 rounded-full"/> : <p></p>}
        <div className="p-3 w-full">
          <p className="text-lg mb-3">{newsItem.title}</p>
          <p className="text-sm">{newsItem.description}</p>
        </div>
      </div>
    </div>
  ));
}

function News() {
  const { t } = useTranslation();

  return (
    <div className="">
      <p className="text-2xl text-white">
        <NewspaperIcon className="h-7 w-7 inline -mt-1" /> {t("news")}
      </p>

      <Suspense fallback={<NewsPreload />}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4">
          {newsData.length > 0 ? renderNews(newsData) : <NewsPreload />}
        </div>
      </Suspense>
    </div>
  );
}

export default News;