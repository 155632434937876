
import "./i18n";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Landing from "./components/landing/Landing";
import LeftNavbar from "./components/leftNavbar/LeftNavbar";
import Header from "./components/header/Header";
import Servers from "./components/servers/Servers";
import Cart from "./components/cart/Cart";
import Auth from "./components/auth/Auth";
import Registration from "./components/registration/Registration";
import Footer from "./components/footer/Footer";
import UserProfile from "./components/userProfile/UserProfile";
import Page404 from "./components/page404/Page404";
import MinecraftShop from "./components/minecraftShop/MinecraftShop";
import Bot from "./components/bot/Bot";
import Plugin from "./components/plugin/Plugin";
import AboutUs from "./components/aboutUs/AboutUs";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function MainContent({ isMobileMenuOpen, setIsMobileMenuOpen }) {
  const { pathname } = useLocation();

  const handleMenuInteraction = () => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    // Закриваємо мобільне меню при зміні маршруту
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [pathname]);

  return (
    <>
      <ScrollToTop />
      <div className="flex-1 px-4">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="servers" element={<Servers />} />
          <Route path="cart" element={<Cart />} />
          <Route path="auth" element={<Auth />} />
          <Route path="registration" element={<Registration />} />
          <Route path="profile" element={<UserProfile />} />
          <Route path="minecraft-shop" element={<MinecraftShop />} />
          <Route path="plugin" element={<Plugin />} />
          <Route path="bot" element={<Bot />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
    </>
  );
}

function App() {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Router>
      <div className="App text-white min-h-screen flex relative">
        {/* Затемнення для мобільного меню */}
        {isMobileMenuOpen && (
          <div
            className="fixed inset-0 bg-neutral-950 opacity-50 z-20"
            onClick={toggleMobileMenu}
          ></div>
        )}
        
        {/* Мобільне меню */}
        <div
          className={`fixed top-0 left-0 h-full bg-neutral-900 z-30 transition-transform duration-300 w-4/5 sm:w-auto ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          } lg:hidden`}
        >
          <LeftNavbar
            isCollapsed={isCollapsed}
            toggleCollapse={handleCollapseToggle}
            onNavigate={() => setIsMobileMenuOpen(false)}
          />
        </div>

        <div className={`hidden lg:block transition-all duration-300 h-dvh sticky top-0 overflow-y-auto ${isCollapsed ? "w-16" : "w-60"}`}>
          <LeftNavbar
            isCollapsed={isCollapsed}
            toggleCollapse={handleCollapseToggle}
          />
        </div>

        <div className="flex-1 bg-neutral-800 transition-all duration-300 min-h-screen flex flex-col justify-between">
          <div className="sticky top-0 z-10 w-auto">
            <Header toggleMobileMenu={toggleMobileMenu} />
          </div>
          
          <MainContent
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
          
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
