// src/components/languageSwitcher/LanguageSwitcher.js
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
const languages = {
  en: { label: "English", flagCode: "GB" }, // ISO код прапора для Англії
  uk: { label: "Українська", flagCode: "UA" },
  ru: { label: "Русский", flagCode: "RU" },
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng); // Зберігаємо вибрану мову в localStorage
    setIsOpen(false); // Закрити список після вибору мови
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Закриття списку при кліку поза його межами
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Визначаємо поточну активну мову
  const currentLanguage = languages[i18n.language]?.label || "Language";

  useEffect(() => {
    // Перевіряємо, чи є збережена мова в localStorage
    const savedLanguage = localStorage.getItem("i18nextLng");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // Встановлюємо збережену мову
    }
  }, [i18n]);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-center w-full font-medium rounded-md focus:outline-none"
      >
        {/* Відображаємо прапор та назву активної мови */}
        <Flag
          code={languages[i18n.language]?.flagCode}
          className="h-7 rounded-md"
        />
      </button>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-0 w-48 rounded-md shadow-lg bg-neutral-900 ring-1 ring-black ring-opacity-5 z-10">
          <div
            className=""
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {Object.keys(languages).map((lng) => (
              <button
                key={lng}
                onClick={() => changeLanguage(lng)}
                className="flex items-center px-4 py-2 text-sm hover:bg-orange-700 rounded-md w-full"
              >
                {/* Прапор та назва мови */}
                <Flag code={languages[lng].flagCode} className="mr-2 w-6 h-4" />
                {languages[lng].label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
