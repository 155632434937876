import React from "react";
import { useTranslation } from "react-i18next";
import ProductCard from "../productCard/ProductCard";

const MinecraftShop = () => {
  const { t } = useTranslation();

  const products = [
    {
      id: 1,
      name: "Epic Chest",
      price: 2.39,
      originalPrice: 2.99,
      discount: 20,
      image: "/chest.webp",
    },
    {
      id: 2,
      name: "Golden Apple",
      price: 5.49,
      originalPrice: 6.99,
      discount: 21,
      image: "/apple.webp",
    },
    {
      id: 3,
      name: "Netherite Sword",
      price: 15.99,
      originalPrice: 18.99,
      discount: 16,
      image: "/sword.webp",
    },
    {
      id: 4,
      name: "Potion of Healing",
      price: 7.99,
      originalPrice: null,
      discount: null,
      image: "/potion.webp",
    },
    {
      id: 5,
      name: "Ender Pearl",
      price: 3.99,
      originalPrice: null,
      discount: null,
      image: "/pearl.webp",
    },
  ];

  return (
    <div className="mb-6">
      <p className="text-start text-2xl mb-6">{t("minecraft_shop_title")}</p>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 md:gap-4">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
      <p className="text-start text-2xl my-6">{t("recent_purchases_title")}</p>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-2 md:gap-4">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
};

export default MinecraftShop;
