import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCart } from "../../context/CartContext";
import { MdShoppingCart, MdOutlineShoppingCart } from "react-icons/md";
import { useSnackbar } from "../../context/SnackbarContext";

const Plugin = () => {
  const { t } = useTranslation();
  const productId = 999;
  const price = 7.99;
  const { addToCart, removeFromCart, isInCart } = useCart();
  const { showSnackbar } = useSnackbar();

  const handleAddToCart = () => {
    if (isInCart(productId)) {
      removeFromCart(productId);
      showSnackbar("removed_from_cart", "warning");
    } else {
      addToCart(productId);
      showSnackbar("added_to_cart", "success");
    }
  };

  return (
    <div className="">
      <div
        className="bg-cover bg-center -mx-4 p-4 md:p-20 lg:p-6 xl:p-20 h-dvh -mt-16 flex items-center"
        style={{ backgroundImage: `url(/plugin-bg.png)` }}
      >
        <div className="text-center flex flex-col justify-center xl:w-3/4 mx-auto">
          <p className="text-3xl md:text-7xl font-bold mb-8">Plugin Name</p>
          <p className="text-sm text-neutral-300 mb-8 leading-normal">
            MEE6 is a complete Discord bot, easy-to-use, that millions of
            Discord servers worldwide trust to manage, entertain, and grow their
            community.
          </p>
          <div className="flex flex-col justify-between sm:justify-start sm:flex-row gap-2 xl:gap-6">
            <button
              onClick={handleAddToCart}
              className="flex-1 flex md:text-lg lg:text-xl text-center rounded-md bg-orange-500 hover:bg-orange-600 transition-all duration-300 ease-in-out"
            >
              <p className="bg-orange-900 text-orange-500 px-4 py-2 md:px-4 lg:px-3 xl:px-4 md:py-4 rounded-s-md">
                {isInCart(productId) ? (
                  <MdShoppingCart className="inline w-6 h-6 me-3 -mt-1 fill-current" />
                ) : (
                  <MdOutlineShoppingCart className="inline w-6 h-6 me-3 -mt-1" />
                )}
                €{price}
              </p>
              <span className="flex-1 px-4 py-2 md:px-4 lg:px-3 xl:px-4 md:py-4 rounded-e-md">
                {isInCart(productId) ? t("remove_from_cart") : t("add_to_cart")}
              </span>
            </button>
            <Link className="flex-1 px-4 py-2 md:px-4 md:py-4 md:text-lg lg:text-xl rounded-md bg-white/10 hover:bg-white/20 transition-all duration-300 ease-in-out">
              <ArrowDownTrayIcon className="inline w-6 h-6 me-3 -mt-1" />{" "}
              {t("download_plugin")}
            </Link>
          </div>
        </div>
      </div>
      <h1 class="text-3xl sm:text-4xl font-bold text-center mb-6">
        Easy <span className="text-orange-500">Setup</span> &{" "}
        <span className="text-orange-500">Configuration</span>
      </h1>
      <div class="flex flex-col lg:flex-row items-center justify-center px-10 text-white gap-x-4">
        <div class="max-w-4xl w-full flex-1">
          <div class="text-lg sm:text-2xl leading-relaxed text-gray-200 flex flex-col justify-between gap-y-10">
            <p className="leading-loose">
              The process has been made{" "}
              <span class="text-orange-500 font-semibold">easier</span> for
              people with no experience can
              <span class="text-orange-500 font-semibold"> setup it</span>{" "}
              <span class="text-2xl">🛠️</span> without too much hastle.
            </p>
            <p className="leading-loose">
              Also our{" "}
              <span class="text-orange-500 font-semibold">24/7 Support</span>{" "}
              <span class="text-2xl">⏰</span> will assist you for the
              installation and{" "}
              <span class="text-orange-500 font-semibold">help you</span> to
              configure the plugin if something is not clarified in
              <span class="text-orange-500 font-semibold"> our wiki page</span>.
            </p>
          </div>
        </div>
        <div class="flex-1 flex flex-col sm:flex-row items-center justify-center gap-4">
          <div class="w-full">
            <img
              src="bot1.webp"
              alt="File structure example"
              class="w-full h-auto rounded-lg"
            />
          </div>
          {/* <div class="bg-neutral-900 p-4 rounded-lg shadow-lg max-w-xs w-full">
        <img src="path/to/your/image2.png" alt="Code example 1" class="w-full h-auto rounded-lg">
      </div>
      <div class="bg-neutral-900 p-4 rounded-lg shadow-lg max-w-xs w-full">
        <img src="path/to/your/image3.png" alt="Code example 2" class="w-full h-auto rounded-lg">
      </div> */}
        </div>
      </div>
      <div
        className="-mx-4 mt-10 p-24 bg-center bg-cover"
        style={{ backgroundImage: `url(/thumb-1920-1317036.png)` }}
      >
        <h1 className="text-4xl mb-16 text-center">
          <span className="text-orange-500">Technic</span> Notice
        </h1>
        <div className="flex flex-col lg:flex-row gap-x-8 text-start">
          <div className="flex-1">
            <h2 className="text-xl text-orange-500 mb-2">Comands</h2>
            <ul className="mb-6 list-disc list-inside">
              <li>/help</li>
              <li>/help</li>
              <li>/help</li>
              <li>/help</li>
              <li>/help</li>
            </ul>

            <h2 className="text-xl text-orange-500 mb-2">
              Supported Database Types
            </h2>
            <ul className="mb-6 list-disc list-inside">
              <li>MySQL</li>
              <li>MariaDB</li>
              <li>MongoDB</li>
            </ul>
          </div>
          <div className="flex-1">
            <h2 className="text-xl text-orange-500 mb-2">Supported Hooks</h2>
            <ul className="mb-6 list-disc list-inside">
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
              <li>lorem ipsum</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plugin;
