function NewsPreload() {
    return (
      <div className="col-span-2 grid grid-cols-2 gap-4">
        {Array.from({ length: 2 }).map((_, index) => (
          <div
            key={index}
            className="bg-orange-900 animate-pulse rounded-md h-64 col-span-1 drop-shadow-lg"
          >
            <div className="h-full bg-gradient-to-b from-transparent to-pink-950 flex items-end p-2 rounded-md">
              <div className="p-3 w-full">
                <p className="text-lg mb-3 bg-gray-300 h-5 w-3/4 rounded"></p>
                <p className="text-sm bg-gray-300 h-3 w-full rounded"></p>
                <p className="text-sm bg-gray-300 h-3 w-5/6 rounded mt-1"></p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

export default NewsPreload;