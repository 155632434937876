import { ChartBarIcon, UsersIcon } from "@heroicons/react/24/solid";
import { ServerIcon, UserIcon, HashtagIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import BotChart from "../botsChart/BotChart";

function BotsStatistics() {
  const { t } = useTranslation();

  const botsArray = [
    {
      id: 1,
      name: "Channel long name name 1",
      users: "1k",
      img: "/news2.png",
    },
    {
      id: 2,
      name: "Channel 2",
      users: "2k",
      img: "/news2.png",
    },
    {
      id: 3,
      name: "Channel 3",
      users: "3k",
      img: "/news2.png",
    },
    {
      id: 4,
      name: "Channel 4",
      users: "4k",
      img: "/news2.png",
    },
    {
      id: 5,
      name: "Channel 5",
      users: "5k",
      img: "/news2.png",
    },
    {
      id: 6,
      name: "Channel 6",
      users: "6k",
      img: "/news2.png",
    },
    {
      id: 7,
      name: "Channel 7",
      users: "7k",
      img: "/news2.png",
    },
    {
      id: 8,
      name: "Channel 8",
      users: "8k",
      img: "/news2.png",
    },
  ];

  function renderBots(botsArray) {
    return botsArray.map((botItem) => (
      <Link
        className="p-2 md:p-3 bg-neutral-900 rounded-md drop-shadow-md flex gap-x-3 hover:bg-orange-700 transition-all duration-300 ease-in-out"
        key={botItem.id}
      >
          <img className="w-14 h-full rounded-full bg-pink-300" src={botItem.img} />
        <div className="text-start truncate flex flex-col gap-y-2">
          <p className="font-bold truncate">{botItem.name}</p>
          <p className="text-neutral-100 text-sm">
            <UsersIcon className="h-4 w-4 inline -mt-1 me-2" />
            {botItem.users}
          </p>
        </div>
      </Link>
    ));
  }

  return (
    <div className="py-4">
      <div className="flex mb-6 justify-between">
      <p className="text-2xl text-white">
        <ChartBarIcon className="h-7 w-7 inline -mt-1" /> {t("bot_statistics")}
      </p>
      <Link to="bot" className="self-end border-b border-transparent hover:border-white text-lg hidden sm:block">{t("nav_bot")}<ArrowRightIcon className="ms-4 h-4 w-4 -mt-1 inline"/></Link>
      </div>
      <div className="-mx-4 flex flex-col sm:flex-row gap-y-6 gap-x-6 sm:gap-x-12 md:gap-x-20 justify-center text-center md:text-start py-6 md:py-16 px-0 sm:px-4 bg-gradient-to-tr from-orange-600 to-pink-600">
        <div className="">
          <p className="text-3xl md:text-5xl">
            <ServerIcon className="h-8 w-8 md:h-14 md:w-14 inline -mt-2 md:-mt-3 me-2 md:me-4" />
            19
          </p>
          <p className="text-xl">{t("nav_servers")}</p>
        </div>
        <div className="">
          <p className="text-3xl md:text-5xl">
            <UserIcon className="h-7 w-7 md:h-12 md:w-12 inline -mt-2 md:-mt-3 me-2 md:me-4" />
            1929
          </p>
          <p className="text-xl">{t("users")}</p>
        </div>
        <div className="">
          <p className="text-3xl md:text-5xl">
            <HashtagIcon className="h-7 w-7 md:h-12 md:w-12 inline -mt-2 md:-mt-3 me-2 md:me-4" />
            400
          </p>
          <p className="text-xl">{t("channels")}</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-x-4 mt-6">
        <div className="w-full bg-neutral-900 rounded-md p-2 flex justify-center items-center drop-shadow-md">
          <BotChart />
        </div>
        <div className="mt-4 xl:mt-0">
          <p className="text-2xl font-bolder">{t("top_channelss")}</p>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-2 gap-4 text-center mt-2 ">
            {botsArray.length > 0 ? renderBots(botsArray) : <>Боти відсутні</>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BotsStatistics;
