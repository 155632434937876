import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-neutral-900 p-4 flex flex-col md:flex-row justify-between gap-y-8 text-center md:text-start text-neutral-300">
      <div className="">
        <p className="text-lg mb-3">{t("footer_nav")}:</p>
        <ul>
          <li>
            <Link to="/" className="hover:text-pink-600">{t("nav_home")}</Link>
          </li>
          <li>
            <Link to="/servers" className="hover:text-pink-600">{t("nav_servers")}</Link>
          </li>
          <li>
            <Link to="/minecraft-shop" className="hover:text-pink-600">{t("nav_minecraft_shop")}</Link>
          </li>
          <li>
            <Link to="/plugin" className="hover:text-pink-600">{t("nav_plugin")}</Link>
          </li>
          <li>
            <Link to="/bot" className="hover:text-pink-600">{t("nav_bot")}</Link>
          </li>
          <li>
            <Link to="/about-us" className="hover:text-pink-600">{t("nav_about")}</Link>
          </li>
        </ul>
      </div>
      <div className="">
        <p className="text-lg mb-3">{t("footer_server")}:</p>
        <p>mc.magatamy.com</p>
      </div>
      <div className="">
        <p className="text-lg mb-3">{t("footer_partners")}:</p>
        <Link><img src="enot-io.svg" className="w-28 mx-auto" alt="" /></Link>
      </div>
      <div className="">
        <p className="text-lg mb-3">{t("footer_media")}:</p>
        <ul>
          <li><Link target="_blank" to="https://discord.gg/Z7VgsBNnxu" className="hover:text-pink-600">Discord</Link></li>
          <li><Link target="_blank" to="https://www.youtube.com/@Magatamy" className="hover:text-pink-600">Youtube</Link></li>
          <li><Link target="_blank" to="https://www.tiktok.com/@magatamy" className="hover:text-pink-600">TikTok</Link></li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
