import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HomeIcon,
  InformationCircleIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  TagIcon,
  Squares2X2Icon,
  ServerStackIcon,
} from "@heroicons/react/24/solid";
import {
  PuzzlePieceIcon,
  StarIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const LeftNavbar = ({ isCollapsed, toggleCollapse, onNavigate  }) => {
  const { t } = useTranslation();
  const menuItems = [
    {
      name: t("nav_home"),
      link: "/",
      icon: (
        <HomeIcon
          className={`transition-all duration-300 ease-in-out ${
            isCollapsed ? "h-8 w-8" : "h-5 w-5"
          }`}
        />
      ),
    },
    {
      name: t("nav_servers"),
      link: "servers",
      icon: (
        <ServerStackIcon
          className={`transition-all duration-300 ease-in-out ${
            isCollapsed ? "h-8 w-8" : "h-5 w-5"
          }`}
        />
      ),
    },
    {
      name: t("nav_products"),
      link: "",
      icon: (
        <Squares2X2Icon
          className={`transition-all duration-300 ease-in-out ${
            isCollapsed ? "h-8 w-8" : "h-5 w-5"
          }`}
        />
      ),
      subItems: [
        {
          name: t("nav_minecraft_shop"),
          link: "minecraft-shop",
          icon: (
            <StarIcon
              className={`transition-all duration-300 ease-in-out ${
                isCollapsed ? "h-8 w-8" : "h-5 w-5"
              }`}
            />
          ),
        },
        {
          name: t("nav_plugin"),
          link: "plugin",
          icon: (
            <PuzzlePieceIcon
              className={`transition-all duration-300 ease-in-out ${
                isCollapsed ? "h-8 w-8" : "h-5 w-5"
              }`}
            />
          ),
        },
        {
          name: t("nav_bot"),
          link: "bot",
          icon: (
            <ChatBubbleBottomCenterTextIcon
              className={`transition-all duration-300 ease-in-out ${
                isCollapsed ? "h-8 w-8" : "h-5 w-5"
              }`}
            />
          ),
        },
      ],
    },
    {
      name: t("nav_about"),
      link: "about-us",
      icon: (
        <InformationCircleIcon
          className={`transition-all duration-300 ease-in-out ${
            isCollapsed ? "h-8 w-8" : "h-5 w-5"
          }`}
        />
      ),
    },
  ];
  const [openCategories, setOpenCategories] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleCategories = () => {
    setOpenCategories(!openCategories);
  };

  useEffect(() => {
    if (!isCollapsed) {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, 300); // 300 мс затримка
      return () => clearTimeout(timer); // Очистити таймер
    } else {
      setIsVisible(false); // Сховати текст, коли меню згорнуте
    }
  }, [isCollapsed]);

  return (
    <div className="bg-neutral-900 px-2 pb-4 h-full flex flex-col justify-between overflow-hidden">
      <nav>
        <div className="flex mb-4">
          <img
            src="./logo.png"
            className={`transition-all duration-300 ease-in-out ${
              isCollapsed ? "h-12 w-12 my-2" : "h-16 w-16"
            }`}
            alt="logo"
          />
          {!isCollapsed && <p className="text-2xl py-3 ps-3">Magatamy</p>}
        </div>
        <ul>
          {menuItems.map((item, index) => (
            <li key={index} className="mb-2">
              {item.subItems ? (
                <>
                  <button
                    onClick={toggleCategories}
                    className={`flex items-center w-full hover:bg-orange-700 p-2 rounded ${
                      isCollapsed ? "flex-col" : ""
                    }`}
                  >
                    <span className={`${isCollapsed ? "" : "mr-2"}`}>
                      {item.icon}
                    </span>
                    {!isCollapsed && item.name}
                    <span
                      className={`transition-all duration-300 ease-in-out ${
                        openCategories ? "transform rotate-180" : ""
                      } ${isCollapsed ? "" : "ms-auto"}`}
                    >
                      <ChevronDownIcon className={`h-5 w-5`} />
                    </span>
                  </button>
                  <ul
                    className={`transition-all duration-300 ease-in-out ${
                      openCategories
                        ? "max-h-40 opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    } ${isCollapsed ? "" : " ml-4"}`}
                  >
                    {item.subItems.map((subItem, subIndex) => (
                      <li key={subIndex} className="mb-1">
                        <Link
                          to={subItem.link}
                          className="flex items-center hover:bg-orange-700 transition-all duration-300 ease-in-out p-2 rounded text-center"
                          onClick={onNavigate} // Закриває меню
                        >
                          <span className="mr-2">{subItem.icon}</span>
                          {!isCollapsed && subItem.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Link
                  to={item.link}
                  className="flex items-center hover:bg-orange-700 transition-all duration-300 ease-in-out p-2 rounded"
                  onClick={onNavigate} // Закриває меню
                >
                  <span className={`${isCollapsed ? "" : "mr-2"}`}>
                    {item.icon}
                  </span>
                  {!isCollapsed && item.name}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </nav>

      <div className="text-left">
        {isCollapsed ? (
          <TagIcon className="h-8 w-8 text-white mx-auto" /> // Іконка при згорнутому меню
        ) : (
          <div
            className={`p-4 rounded-md bg-gradient-to-tr from-orange-600 to-pink-800 transition-opacity duration-300 ease-in-out ${
              isVisible ? "opacity-100" : "opacity-0"
            }`}
          >
            <div
              className={`transform transition-transform duration-500 ease-in-out ${
                isVisible ? "scale-100" : "scale-0"
              }`}
            >
              <p className="text-xl mb-2 font-semibold">20% discount</p>
              <p>on all packages hurry up now!</p>
              <button className="w-full py-1 mt-4 text-orange-600 bg-white text-center rounded-md drop-shadow-md transition-all duration-300 ease-in-out text-lg hover:bg-pink-700 hover:text-white">
                Go shopping
              </button>
            </div>
          </div>
        )}

        <button
          onClick={toggleCollapse}
          className="hidden lg:flex mt-4 w-full py-2 border-2 rounded items-center justify-center hover:border-orange-700 hover:text-orange-700 transition-all duration-300 ease-in-out"
        >
          {isCollapsed ? (
            <ChevronLeftIcon className="h-6 w-6 rotate-180" />
          ) : (
            <p className="me-2">
              <ChevronLeftIcon className="h-6 w-6 inline -mt-1 me-2" />
              {t("nav_collapse")}
            </p>
          )}
        </button>
        <Link
          to="profile"
          className="lg:hidden mt-4 border border-neutral-500 flex py-2 px-4 gap-x-4 items-center bg-neutral-800 rounded-md"
        >
          <img src="/news1.png" className="w-10 h-10 rounded-full" alt="" />
          <p className=" text-center flex-1">UserNickname</p>
        </Link>
      </div>
    </div>
  );
};

export default LeftNavbar;
