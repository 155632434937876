import React from "react";
import { FaDiscord, FaGoogle } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

function UserProfile() {
  const { t } = useTranslation();

  return (
    <div className="">
      {/* Верхня частина профілю */}
      <div className="-mx-4 flex flex-col sm:flex-row items-center gap-6 bg-gradient-to-t from-orange-900 to-orange-900/10 p-6">
        <img
          src="/news1.png" // Дефолтна аватарка
          alt={t("avatar_alt")}
          className="w-28 h-28 rounded-full border-4 border-neutral-700"
        />
        <div className="text-white text-start w-full flex justify-between">
          <div className="">
            <h2 className="text-2xl font-semibold">UserNickname</h2>{" "}
            {/* Нікнейм */}
            <p className="text-neutral-300">user@example.com</p> {/* Емейл */}
          </div>
          <div className="flex gap-x-4"></div>
        </div>
      </div>

      {/* Основна частина профілю */}
      <div className="md:grid md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-3 gap-6 my-8">
        {/* Права колонка - Поле для промокоду */}
        <div className="col-span-1 lg:col-span-2 xl:col-span-1 bg-neutral-800 py-2 lg:p-4 rounded-md">
          <h3 className="text-lg font-semibold text-white mb-4">
            {t("activate_promo_code")}
          </h3>
          <input
            type="text"
            placeholder={t("enter_promo_code")}
            className="w-full p-2 rounded-md bg-neutral-700 text-white outline-none"
          />
          <button className="mt-4 w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
            {t("activate")}
          </button>
          <hr className="my-6 border-neutral-500" />
          <button className="w-full flex items-center justify-center bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 mb-4">
            <FaDiscord alt="Discord" className="h-5 w-5 mr-2" />
            {t("link_discord")}
          </button>
          <button className="w-full flex items-center justify-center bg-red-600 text-white py-3 rounded-md hover:bg-red-700 mb-4">
            <FaGoogle alt="Google" className="h-5 w-5 mr-2" />
            {t("link_google")}
          </button>
          <button className="w-full py-3 border text-white rounded-md hover:bg-orange-700">
            {t("logout")}
          </button>
        </div>
        {/* Ліва колонка - Інвентар */}
        <div className="md:col-span-1 lg:col-span-3 xl:col-span-2 bg-neutral-800 py-2 lg:p-4 rounded-md">
          <h3 className="text-lg font-semibold text-white mb-4">{t("inventory")}</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div className="">
              <img className="rounded-md" src="/news2.png" alt={t("inventory_item_alt")} />
            </div>
            <div className="">
              <img className="rounded-md" src="/news2.png" alt={t("inventory_item_alt")} />
            </div>
            <div className="">
              <img className="rounded-md" src="/news2.png" alt={t("inventory_item_alt")} />
            </div>
            <div className="">
              <img className="rounded-md" src="/news2.png" alt={t("inventory_item_alt")} />
            </div>
            <div className="">
              <img className="rounded-md" src="/news2.png" alt={t("inventory_item_alt")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfile;
