import React, { createContext, useState, useContext } from "react";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (id) => {
    setCartItems((prev) => [...prev, id]);
  };

  const removeFromCart = (id) => {
    setCartItems((prev) => prev.filter((item) => item !== id));
  };

  const isInCart = (id) => cartItems.includes(id);

  return (
    <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, isInCart }}>
      {children}
    </CartContext.Provider>
  );
};
