import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function Page404() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Перенаправлення на головну сторінку
  };

  return (
    <div className="h-full flex flex-col items-center justify-center text-white text-center">
      <h1 className="text-9xl font-bold text-pink-700">404</h1> 
      <h2 className="text-2xl mt-4">{t("page_404_not_found")}</h2> 
      <p className="text-gray-400 mt-2">{t("page_404_error")}</p>
      
      <button
        onClick={handleGoHome}
        className="mt-6 px-6 py-2 border rounded-md hover:border-orange-600 hover:text-orange-600 transition-colors"
      >
        {t("page_404_return")}
      </button>
    </div>
  );
}

export default Page404;
