// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./locales/en/translation.json";
import uk from "./locales/uk/translation.json";
import ru from "./locales/ru/translation.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      uk: { translation: uk },
      ru: { translation: ru },
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
    lng: localStorage.getItem('i18nextLng') || (navigator.language ? navigator.language.split('-')[0] : 'en'), // використовуємо збережену мову або мову браузера
    fallbackLng: "en", // Використовуйте цю мову, якщо переклад не знайдено
    interpolation: {
      escapeValue: false, // React вже захищає від XSS
    },
  });

export default i18n;
